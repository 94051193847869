import React from "react"

import Layout from "../components/layout"
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import Slider from "../components/Slider"
import useInicio from "../Hooks/userInicio"
import AboutRolteca from "../components/AboutRolteca"
import TeamRolteca from "../components/TeamRolteca"
import TextImageLeft from "../components/TextImageLeft"
import TextImageRight from "../components/TextImageRight"
import CallToAction from "../components/CallToAction"
import BlogResume from "../components/BlogResume"
import GamesResume from "../components/GamesResume"
import PageHeader from "../components/Header"

const IndexPage = () => {
  const indexData = useInicio();
  const blogData = indexData.blog;
  /*<GamesResume/>*/
  return (
    <Layout
        title="Rolteca | Tu rincón de recursos para juegos de rol"
        description="Rolteca es un proyecto en el que queremos desarrollar aplicaciones para mejorar la experiencia de juego tanto en juegos de tablero como de rol"
        slug={""}>
      <Nav />
      <PageHeader title="Rolteca" />
      <AboutRolteca
          title = {indexData.firstTitle}
          textUpperCount = {indexData.textUpperCount}
          textUnderCount = {indexData.textUnderCount}/>

      <TeamRolteca/>
      <TextImageLeft
        title={indexData.titleSectionOne}
        image={indexData.imageSectionOne}
        text={indexData.textSectionOne}/>
      <CallToAction/>
      <TextImageRight
        title={indexData.titleSectionTwo}
        image={indexData.imageSectionTwo}
        text={indexData.textSectionTwo}/>
      <BlogResume
        articles={blogData}/>
      <Footer />
    </Layout>
  )
}

export default IndexPage
