import { graphql, useStaticQuery } from "gatsby"

const useInicio = () => {

  const response = useStaticQuery(
    graphql`
        query indexPage {
            strapiHomePage {
                CountTItle
                TextSectionOne
                TextSectionTwo
                TextUnderCount
                TextUpperCount
                TitleSectionOne
                TitleSectionTwo
                ImageSectionOne{
                    sharp: childImageSharp {
                        fluid( maxWidth: 1200 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                ImageSectionTwo {
                    sharp: childImageSharp {
                        fluid( maxWidth: 1200 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            allStrapiBlogArticle(limit: 3, sort: {fields: updated_at, order: DESC}) {
                nodes {
                    categories {
                        name
                        slug
                    }
                    title
                    shortdescription
                    content
                    created_at(formatString: "DD-MM-YYYY")
                    slug
                    metadescription
                    image {
                        sharp: childImageSharp {
                            fluid( maxWidth: 1200 ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `
  )
  let resoponseObject = {
    firstTitle: response.strapiHomePage.CountTItle,
    textSectionOne: response.strapiHomePage.TextSectionOne,
    textSectionTwo: response.strapiHomePage.TextSectionTwo,
    textUnderCount: response.strapiHomePage.TextUnderCount,
    textUpperCount: response.strapiHomePage.TextUpperCount,
    titleSectionOne:response.strapiHomePage.TitleSectionOne,
    titleSectionTwo:response.strapiHomePage.TitleSectionTwo,
    imageSectionOne:response.strapiHomePage.ImageSectionOne,
    imageSectionTwo:response.strapiHomePage.ImageSectionTwo,
    projects:[],
    blog: []
  };
  //TODO Load Projects data

  //Load Blog data
  response.allStrapiBlogArticle.nodes.map(article =>{
    let categories = [];
    article.categories.map(category =>{
      categories.push(
        {
          name: category.name,
          slug: category.slug
        })
    })
    resoponseObject.blog.push({
      title:article.title,
      shortdescription:article.shortdescription,
      image: article.image,
      date: article.created_at,
      categories,
      slug:article.slug
    })
  });
  return resoponseObject;
}

export default useInicio;
