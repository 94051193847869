import React from "react";

const CallToAction = () => {
  return (
    <div className="cta-two">
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-lg-6 thm-base-bg">
            <div className="cta-two__single">
              <div className="cta-two__icon">
                <span>
                  <i className="kipso-icon-professor"></i>
                </span>
              </div>
              <div className="cta-two__content">
                <h2 className="cta-two__title">¿Quieres colaborar? </h2>
                <p className="cta-two__text">
                  Las puertas están abiertas a todo el que quiera ayudar <br/> a la comunidad
                </p>
                <a href="/contacta" className="thm-btn cta-two__btn">
                  Contactar
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 thm-base-bg-2">
            <div className="cta-two__single">
              <div className="cta-two__icon">
                <span>
                  <i className="kipso-icon-knowledge"></i>
                </span>
              </div>
              <div className="cta-two__content">
                <h2 className="cta-two__title">Nuestra comunidad</h2>
                <p className="cta-two__text">
                  Aúnete a nuestra comunidad de Discord para encontrar <br/> otros roleros como tú
                </p>
                <a href="https://discord.gg/4Dqd9q6" target="_blank" rel="noreferrer" className="thm-btn cta-two__btn">
                  Únirme
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
