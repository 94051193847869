import React from "react";
import blog1 from "../assets/images/blog-1-1.jpg";
import blog2 from "../assets/images/blog-1-2.jpg";
import blog3 from "../assets/images/blog-1-3.jpg";
import Img from "gatsby-image"
import Link from "gatsby-link"

const BlogResume = ({articles}) => {
  return (
    <section className="blog-one  blog-one__home-two">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Articulos & novedades
          </h2>
        </div>
        <div className="row">
          {articles.map(elem =>(
            <div key={elem.slug} className="col-lg-4">
              <div className="blog-one__single">
                <div className="blog-one__image">
                  <Img fluid={{ ...elem.image.sharp.fluid, aspectRatio: 11 / 9 }} alt={elem.title} />
                  <Link className="blog-one__plus" to={elem.slug}>
                    <i className="kipso-icon-plus-symbol"></i>
                  </Link>
                </div>
                <div className="blog-one__content text-center">
                  <h3 className="blog-one__title">
                    <Link to={elem.slug}>
                      {elem.title}
                    </Link>
                  </h3>
                  <p className="blog-one__text blog-one__resume">
                    {elem.shortdescription }
                  </p>
                  <Link to={elem.slug}  className="blog-one__link">
                    Leer
                  </Link>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
      <div className="block-title text-center">
        <Link to="/blog" className="thm-btn about-one__btn">
          Ver más
        </Link>
      </div>
    </section>
  );
};

export default BlogResume;
