import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ClientStripe from "../assets/images/circle-stripe.png";

class AboutRolteca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startCounter: false
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className="about-one ">
        <img src={ClientStripe} className="about-one__circle" alt="" />
        <div className="container text-center">
          <div className="block-title text-center">
            <h2 className="block-title__title">
              {this.props.title}
            </h2>
          </div>
          <p className="about-one__text">
            {this.props.textUpperCount}
          </p>
          <div className="row about-one__text">
            <div className="col-lg-4">
              <div className="about-one__review">
                <p className="about-one__review-count counter">
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}

                    delayedCall
                  >
                    <CountUp end={this.state.startCounter ? 2489 : 0} />
                  </VisibilitySensor>
                </p>
                <p className="about-one__review-text">Ayudas</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="about-one__review">
                <p className="about-one__review-count counter">
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}

                    delayedCall
                  >
                    <CountUp end={this.state.startCounter ? 225 : 0} />
                  </VisibilitySensor>
                </p>
                <p className="about-one__review-text">Juegos</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="about-one__review">
                <p className="about-one__review-count counter">
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}

                    delayedCall
                  >
                    <CountUp end={this.state.startCounter ? 1800 : 0} />
                  </VisibilitySensor>
                </p>
                <p className="about-one__review-text">Módulos</p>
              </div>
            </div>
          </div>

          <p className="about-one__text">
            {this.props.textUnderCount}
          </p>
          <a href="#none" className="thm-btn about-one__btn">
            Ver
          </a>
        </div>
      </section>
    );
  }
}

export default AboutRolteca;
