import React from "react"
import Img from "gatsby-image"

const TextImageRight = ({ title, image, text }) => {
  return (
    <section className="meeting-one">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="meeting-one__content">
              <div className="block-title text-center">
                <h2 className="block-title__title">
                  {title}<br />
                </h2>
              </div>
              <p className="meeting-one__block-text text-justify">
                {text}
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="meeting-one__image">
              <Img fluid={image.sharp.fluid} alt="Awesome alter text" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextImageRight
