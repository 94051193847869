import { graphql, useStaticQuery } from "gatsby"

const useNosotros = () => {

  const response = useStaticQuery(
    graphql`
        query useProyectos {
            allStrapiProjects {
                nodes {
                    id
                    title
                    slug
                    shortdescription
                    img {
                        sharp: childImageSharp {
                            fluid( maxWidth: 1200 ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `
  )


  return response.allStrapiProjects.nodes
}

export default useNosotros;
